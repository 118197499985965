import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <section>
      <h1>404: Not found</h1>
      <p>Woops... that page doesn&apos;t seem to exist.</p>
      <Link to="/" className="inline-block">
        {`< `}Back to Home Page
      </Link>
    </section>
  );
};

export default NotFoundPage;
